import dayjs from "dayjs";

function message(notification){
  let message_date = null;

  if(notification.payload.action_type==='change'){
      message_date = 'изменил дату ' + dayjs(notification.payload.end_date).format('DD.MM.YYYY');
  }

  if(notification.payload.action_type==='set'){
    message_date = 'установил дату на ' + dayjs(notification.payload.end_date).format('DD.MM.YYYY');
  }

  if(notification.payload.action_type==='delete'){
    message_date = 'удалил дату ' + dayjs(notification.payload.end_date).format('DD.MM.YYYY');
  }

  return `
         <span class='font-bold'>${message_date}</span>
       </spam>`;
}

export default function (notification){
    const payload = notification.payload;
    return {
      id: notification.id,
      text: message(notification),
      isShowPipeline: true,
      date_notify: notification.created_at,
      link: null,
      pipeline: {
        id: payload.pipeline_id,
        name: payload.pipeline_name,
        status:{
          status_id: payload.status_id,
          name: payload.status_name,
        },
        deal: {
          id: payload.deal_id,
          name: payload.deal_name,
          user: payload.user,
          date:{
              start_date: notification.payload.action_type === 'delete'? null: notification.payload.start_date,
              end_date: notification.payload.action_type === 'delete' ? null: notification.payload.end_date,
          }
        },
      },
    };
}
