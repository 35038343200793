import authService from '../services/auth.service';
import axios from 'axios';

const state = () => ({
    isAuthenticated: false,
    showModalDealFields: false,
    access_token: localStorage.getItem('access_token'),
    pipeline_image: null,
    refresh_token: localStorage.getItem('refresh_token'),
    userData: {
        id: null,
        is_blocked: false,
        is_admin: null,
        role_title: null,
        subscriptions: [],
        isShowOnlyNotReadNotifications: localStorage.getItem('show_only_not_read_notifications') ?? false,
        isSoundNotify: localStorage.getItem('is_sound_notify') ?? true,
        isLogged: false,
        name: null,
        surname: null,
        userPermissions: null,
        notifications: [],
        email: null,
        image: null,
        role: null,
        _refresh: null,
        _token: null,
    },
    unread_deal: {},
    userAccessRules: {},
    userRole: null,
    fakeUsersMap: {
        'admin@volga.com': 0,
        'manager@volga.com': 1,
        'customer@volga.com': 2,
    },
    fakeTokensMap: {
        admin_token: 0,
        manager_token: 1,
        customer_token: 2,
    },
    fakeUserData: [
        {
            name: 'Vasili',
            surname: 'Pupkin',
            email: 'admin@volga.com',
            image: 'https://via.placeholder.com/150',
            role: 'admin',
            _refresh: 'my_refresh_token',
            _access: 'admin_token',
            access_rules: {
                pages: {
                    create: true,
                    update: true,
                    delete: true,
                },
                users: {
                    create: true,
                    update: true,
                    delete: true,
                },
                booking: {
                    create: true,
                    update: true,
                    delete: true,
                },
                cruises: {
                    create: false,
                    update: false,
                    delete: false,
                },
            },
        },
        {
            name: 'Vasili',
            surname: 'Pupkin',
            email: 'manager@volga.com',
            image: 'https://via.placeholder.com/150',
            role: 'manager',
            _refresh: 'my_refresh_token',
            _access: 'manager_token',
            access_rules: {
                pages: {
                    create: true,
                    update: true,
                    delete: true,
                },
                booking: {
                    create: true,
                    update: true,
                    delete: true,
                },
                cruises: {
                    create: false,
                    update: false,
                    delete: false,
                },
            },
        },
        {
            name: 'Vasili',
            surname: 'Pupkin',
            email: 'customer@volga.com',
            image: 'https://via.placeholder.com/150',
            role: 'customer',
            _refresh: 'my_refresh_token',
            _access: 'customer_token',
            access_rules: {
                cruises: {
                    create: false,
                    update: false,
                    delete: false,
                },
                booking: {
                    create: true,
                    update: true,
                },
            },
        },
    ],
});

const mutations = {
    setNotifications(state, payload) {
        state.userData.notifications = payload;
    },
    setSubscriptions(state, setSubscriptions) {
        state.userData.subscriptions = setSubscriptions;
    },
    setUnreadDeal(state, payload) {
        if (Object.keys(payload).length > 0) {
            state.unread_deal = payload;
        } else {
            state.unread_deal = {};
        }
    },
    setUnreadDealFlag(state, payload) {
        state.unread_deal[payload.deal_id] = payload.is_read;
    },
    readAllUnreadDeal(state) {
        for (let key of Object.keys(state.unread_deal)) {
            state.unread_deal[key] = false;
        }
    },
    readNotificationById(state, payload) {
        if (state.userData.notifications) {
            if (state.userData.isShowOnlyNotReadNotifications) {
                state.userData.notifications = state.userData.notifications.filter(
                    (notification) => notification.id !== payload
                );
            } else {
                const notification = state.userData.notifications.filter(
                    (notification) => notification.id === payload
                )[0];
                notification.read = true;
            }
        }
    },
    readAllNotifications(state) {
        if (state.userData.notifications) {
            if (state.userData.isShowOnlyNotReadNotifications) {
                state.userData.notifications = [];
            } else {
                state.userData.notifications.map((notification) => {
                    notification.read = true;
                });
            }
        }
    },
    //Todo переписать этот позор
    setUserData(state, payload) {
        for (const key of Object.keys(state.userData)) {
            try {
                state.userData[key] = payload.user[key];
            } catch (e) {
                state.userData[key] = payload[key];
            }
        }
        try {
            state.userData.is_admin = payload.user.role_title.includes('admin');
            state.access_token = payload.token;
        } catch (e) {}
        localStorage.setItem('access_token', payload.token);

        state.isAuthenticated = true;
    },
    setRefreshUserData(state, payload) {
        for (const key of Object.keys(payload)) {
            state.userData[key] = payload[key];
        }
        state.userData.i;
        state.isAuthenticated = true;
    },
    setField(state, payload) {
        state.showModalDealFields = !state.showModalDealFields;
    },
    clearTokens(state) {
        state.refresh_token = null;
        state.access_token = null;
        localStorage.removeItem('access_token');
    },
    setPipelineImage(state, payload) {
        state.pipeline_image = payload;
    },
    setIsShowOnlyNotReadNotifications(state, payload) {
        state.userData.isShowOnlyNotReadNotifications = payload;
        localStorage.setItem('show_only_not_read_notifications', payload);
    },
    setIsSoundNotification(state, payload) {
        state.userData.isSoundNotify = payload;
        localStorage.setItem('is_sound_notify', payload);
    },
};

const getters = {
    getUser: (state) => state.userData,
    getSubscriptions: (state) => state.userData.subscriptions,
    getUserPermission: (state) => state.userData.userPermissions,
    getNotify: (state) => state.userData.notifications,
    getNotifyCount: (state) => state.userData.notifications.length,
    getIsShowOnlyNotReadNotifications: (state) =>
        state.userData.isShowOnlyNotReadNotifications
            ? JSON.parse(state.userData.isShowOnlyNotReadNotifications)
            : false,
    getIsSoundNotify: (state) => (state.userData.isSoundNotify ? JSON.parse(state.userData.isSoundNotify) : false),
    getToken: (state) => state.userData._token,
    getFakes: (state) => state.fakeUserData,
    isAuthenticated: (state) => state.isAuthenticated,
    getUserRole: (state) => state.userData.role,
    getAccessToken: (state) => state.access_token,
    getRefreshToken: (state) => state.refresh_token,
    getUserAccessRules: (state) => state.userAccessRules,
    getCountUnreadDeal: (state) => (deal_id) => {
        return state.unread_deal[deal_id];
    },
    hasAdmin: (state) => state.userData.role_title.includes('admin'),
};

const actions = {
    async logOut({ commit }) {
        try {
            commit('setUserData', {
                id: null,
                is_blocked: false,
                role_title: null,
                isLogged: false,
                name: null,
                surname: null,
                email: null,
                image: null,
                role: null,
                _refresh: null,
                _token: null,
            });
            commit('clearTokens');
        } catch (ex) {
            console.log('cant logout: ' + ex);
        }
    },
    async login({ state, commit, getters }, payload) {
        try {
            const user = await authService.login({ state, commit, getters }, payload);
            if (user) commit('setUserData', user);
        } catch (ex) {
            if (ex.response.status > 400 && ex.response.status < 500) {
                return `Не верный логин или пароль (${ex.response.status})`;
            }
            if (ex.response.status >= 500) {
                return `Внутренняя ошибка сервера (${ex.response.status})`;
            }
            console.error('cant login: ' + ex);
        }
    },
    async downloadImage({ state, commit, getters }, formData) {
        const response = await axios.post(`/pipeline/${formData.get('id')}?_method=patch`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const { data } = await axios.get(`/pipeline/${formData.get('id')}/edit`);
        state.pipeline_image = data.data.image;
    },
    async verifyToken({ state, getters, commit }) {
        try {
            const user = await authService.verifyToken({ state, getters, commit });
            if (user) {
                commit('setRefreshUserData', user);

                state.userData.is_admin = user.role_title.includes('admin');
            }
        } catch (ex) {
            console.error('cant verify token: ' + ex);
        }
    },

    async getUser() {
        return await axios.get('/profile');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
