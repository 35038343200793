<template>
    <a
        v-if="deal"
        class="flex flex-col gap-2 p-2 rounded-md bg-white"
        :href="`/pipeline/${pipelineId}/show/deal/${deal.id}`"
        @click.prevent.stop="toRoute(`/pipeline/${pipelineId}/show/deal/${deal.id}`)"
    >
        <UserNotificationDealUser v-if="deal.user" :user="deal.user" @show-modal-user="handleShowModalUser" />
        <p class="text-left mb-0" v-else>{{ deal.name }}</p>
        <NotificationDate v-if="deal.date && deal?.date.end_date != null" :date="deal.date" />
    </a>
</template>

<script>
import UserNotificationDealUser from '@/components/notifications/UserNotificationDealUser';
import NotificationDate from '@/components/notifications/NotificationDate';

export default {
    name: 'UserNotificationDeal',
    components: {
        UserNotificationDealUser,
        NotificationDate,
    },
    props: {
        pipelineId: {
            required: true,
        },
        deal: {
            required: true,
        },
        notify: {
            required: true,
        },
    },
    methods: {
        toRoute(link) {
            this.$emit('onClickNotify');
            const options = { path: link };
            if (this.notify.note_id) {
                options.hash = `#note_id=${this.notify.note_id}`;
            }

            this.$router.push(options);
        },
        handleShowModalUser(user) {
            this.$emit('show-modal-user', user);
        },
    },
};
</script>

<style scoped lang="scss"></style>
