import { helper as $h } from '@/utils/helper';

function message(notification) {
    let soon = 'истечет';
    if ($h.getDayjs() > $h.getDayjs(notification.payload.deal_date.end_date)) {
        soon = 'истекла';
    }

    return `Напоминание дата карточки
            ${soon} <span class="font-bold">${$h.formNow(notification.payload.deal_date.end_date, true)}</span>
        </span>`;
}

//Напоминание: дата карточки «209 Центрифуга настольная MDV» истекла вчера в 17:55
export default function(notification) {
    const payload = notification.payload;
    return {
        id: notification.id,
        text: message(notification),
        isShowPipeline: true,
        date_notify: notification.created_at,
        link: null,
        pipeline: {
            id: payload.pipeline_id,
            name: payload.pipeline_name,
            status: {
                status_id: payload.status_id,
                name: payload.status_name,
            },
            deal: {
                id: payload.deal_id,
                name: payload.deal_name,
                user: payload.user,
                date: notification.payload.deal_date,
            },
        },
    };
}
