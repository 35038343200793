import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import ru from 'dayjs/locale/ru';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { serialize } from '@/utils/object-to-form-data';
import updateLocale from 'dayjs/plugin/updateLocale';
import ruLocale from '@/utils/ru-locale';

dayjs.extend(duration);
dayjs.locale('ru');
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);

const helpers = {
    cutText(text, length) {
        if (text.split(' ').length > 1) {
            const string = text.substring(0, length);
            const splitText = string.split(' ');
            splitText.pop();
            return splitText.join(' ') + '...';
        } else {
            return text;
        }
    },
    getDayjs(date) {
        return dayjs(date).tz('Europe/Moscow');
    },
    formatDate(date, format) {
        return dayjs(date).format(format);
    },
    capitalizeFirstLetter(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        } else {
            return '';
        }
    },
    onlyNumber(string) {
        if (string) {
            return string.replace(/\D/g, '');
        } else {
            return '';
        }
    },
    formNow: function(date, is_notify = false) {
        if (is_notify) {
            return dayjs(date)
                .locale('ru', ruLocale)
                .tz('Europe/Moscow')
                .fromNow();
        }
        dayjs.locale('ru', ru);
        return dayjs(date)
            .tz('Europe/Moscow')
            .fromNow();
    },
    formatCurrency(number) {
        if (number) {
            const formattedNumber = number.toString().replace(/\D/g, '');
            const rest = formattedNumber.length % 3;
            let currency = formattedNumber.substr(0, rest);
            const thousand = formattedNumber.substr(rest).match(/\d{3}/g);
            let separator;

            if (thousand) {
                separator = rest ? '.' : '';
                currency += separator + thousand.join('.');
            }

            return currency;
        } else {
            return '';
        }
    },
    timeAgo(time) {
        const date = new Date((time || '').replace(/-/g, '/').replace(/[TZ]/g, ' '));
        const diff = (new Date().getTime() - date.getTime()) / 1000;
        const dayDiff = Math.floor(diff / 86400);

        if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
            return dayjs(time).format('MMMM DD, YYYY');
        }

        return (
            (dayDiff === 0 &&
                ((diff < 60 && 'сейчас') ||
                    (diff < 120 && '1 месяц назад') ||
                    (diff < 3600 && Math.floor(diff / 60) + ' минут назад') ||
                    (diff < 7200 && '1 hour ago') ||
                    (diff < 86400 && Math.floor(diff / 3600) + 'часа назад'))) ||
            (dayDiff === 1 && 'вчера') ||
            (dayDiff < 7 && dayDiff + 'дней назад') ||
            (dayDiff < 31 && Math.ceil(dayDiff / 7) + 'недели назад')
        );
    },
    diffTimeByNow(time) {
        const startDate = dayjs(
            dayjs()
                .format('YYYY-MM-DD HH:mm:ss')
                .toString()
        );
        const endDate = dayjs(
            dayjs(time)
                .format('YYYY-MM-DD HH:mm:ss')
                .toString()
        );

        const duration = dayjs.duration(endDate.diff(startDate));
        const milliseconds = Math.floor(duration.asMilliseconds());

        const days = Math.round(milliseconds / 86400000);
        const hours = Math.round((milliseconds % 86400000) / 3600000);
        let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
        const seconds = Math.round((((milliseconds % 86400000) % 3600000) % 60000) / 1000);

        if (seconds < 30 && seconds >= 0) {
            minutes += 1;
        }

        return {
            days: days.toString().length < 2 ? '0' + days : days,
            hours: hours.toString().length < 2 ? '0' + hours : hours,
            minutes: minutes.toString().length < 2 ? '0' + minutes : minutes,
            seconds: seconds.toString().length < 2 ? '0' + seconds : seconds,
        };
    },
    isset(obj) {
        if (obj !== null && obj !== undefined) {
            if (typeof obj === 'object' || Array.isArray(obj)) {
                return Object.keys(obj).length;
            } else {
                return obj.toString().length;
            }
        }

        return false;
    },
    toRaw(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    randomNumbers(from, to, length) {
        const numbers = [0];
        for (let i = 1; i < length; i++) {
            numbers.push(Math.ceil(Math.random() * (from - to) + to));
        }

        return numbers;
    },
    allowedMenu(menu, rules, router) {
        const res = [];
        for (const item of menu) {
            if (item.hasOwnProperty('subMenu')) {
                const subMenu = this.allowedMenu(item.subMenu, rules, router);
                if (subMenu.length > 0) {
                    res.push({ ...item, subMenu });
                }
            } else {
                const route = router.resolve({ name: item.pageName, params: item.params });
                if (route && route.meta && route.meta.auth) {
                    res.push(item);
                    // if (route.meta.auth.includes(rules)) {
                    //   res.push(item)
                    // }
                }
            }
        }
        return res;
    },
    customTransliterate(text) {
        text = text
            .toLowerCase()
            .replace(/\u0401/g, 'YO')
            .replace(/\u0419/g, 'I')
            .replace(/\u0426/g, 'TS')
            .replace(/\u0423/g, 'U')
            .replace(/\u041A/g, 'K')
            .replace(/\u0415/g, 'E')
            .replace(/\u041D/g, 'N')
            .replace(/\u0413/g, 'G')
            .replace(/\u0428/g, 'SH')
            .replace(/\u0429/g, 'SCH')
            .replace(/\u0417/g, 'Z')
            .replace(/\u0425/g, 'H')
            .replace(/\u042A/g, '')
            .replace(/\u0451/g, 'yo')
            .replace(/\u0439/g, 'i')
            .replace(/\u0446/g, 'ts')
            .replace(/\u0443/g, 'u')
            .replace(/\u043A/g, 'k')
            .replace(/\u0435/g, 'e')
            .replace(/\u043D/g, 'n')
            .replace(/\u0433/g, 'g')
            .replace(/\u0448/g, 'sh')
            .replace(/\u0449/g, 'sch')
            .replace(/\u0437/g, 'z')
            .replace(/\u0445/g, 'h')
            .replace(/\u044A/g, "'")
            .replace(/\u0424/g, 'F')
            .replace(/\u042B/g, 'I')
            .replace(/\u0412/g, 'V')
            .replace(/\u0410/g, 'a')
            .replace(/\u041F/g, 'P')
            .replace(/\u0420/g, 'R')
            .replace(/\u041E/g, 'O')
            .replace(/\u041B/g, 'L')
            .replace(/\u0414/g, 'D')
            .replace(/\u0416/g, 'ZH')
            .replace(/\u042D/g, 'E')
            .replace(/\u0444/g, 'f')
            .replace(/\u044B/g, 'i')
            .replace(/\u0432/g, 'v')
            .replace(/\u0430/g, 'a')
            .replace(/\u043F/g, 'p')
            .replace(/\u0440/g, 'r')
            .replace(/\u043E/g, 'o')
            .replace(/\u043B/g, 'l')
            .replace(/\u0434/g, 'd')
            .replace(/\u0436/g, 'zh')
            .replace(/\u044D/g, 'e')
            .replace(/\u042F/g, 'Ya')
            .replace(/\u0427/g, 'CH')
            .replace(/\u0421/g, 'S')
            .replace(/\u041C/g, 'M')
            .replace(/\u0418/g, 'I')
            .replace(/\u0422/g, 'T')
            .replace(/\u042C/g, "'")
            .replace(/\u0411/g, 'B')
            .replace(/\u042E/g, 'YU')
            .replace(/\u044F/g, 'ya')
            .replace(/\u0447/g, 'ch')
            .replace(/\u0441/g, 's')
            .replace(/\u043C/g, 'm')
            .replace(/\u0438/g, 'i')
            .replace(/\u0442/g, 't')
            .replace(/\u044C/g, "'")
            .replace(/\u0431/g, 'b')
            .replace(/\u044E/g, 'yu')
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '');

        return text;
    },
    formPreparation(form, options, formSerialize = null) {
        const formData = new FormData();
        const defaultOptions = {
            /**
             * добавляет индексы массивам
             * defaults to false
             */
            indices: true,

            /**
             * treat null values like undefined values and ignore them
             * defaults to false
             */
            nullsAsUndefineds: false,

            /**
             * convert true or false to 1 or 0 respectively
             * defaults to false
             */
            booleansAsIntegers: true,

            /**
             * store arrays even if they're empty
             * defaults to false
             */
            allowEmptyArrays: false,
        };

        if (!formSerialize) {
            formSerialize = form;
        }

        return serialize(formSerialize, Object.assign({}, defaultOptions, options), formData);
    },
};

const install = (app) => {
    app.config.globalProperties.$h = helpers;
};

export { install as default, helpers as helper };
