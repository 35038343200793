<template>
    <div class="flex">
        <BaseBadge
            status="default"
            :message="data.name"
            :icon="data.is_source ? 'SunIcon' : null"
            :style="{ 'background-color': data.color, color: data.color_text }"
            class="badge--sm"
        >
        </BaseBadge>
    </div>
</template>

<script>
import BaseBadge from '@/components/base/BaseBadge';

export default {
    name: 'Label',
    components: {
        BaseBadge,
    },
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
};
</script>

<style scoped></style>
