function message(notification) {
    return `
        удалил вас из воронки <span class="font-bold">${notification.payload.pipeline_name}</span>
    `;
}

export default function(notification) {
    return {
        id: notification.id,
        text: message(notification),
    };
}
