function message(notification) {
    return `
      <span>
            добавил вас Исполнителем
        </span>`;
}
export default function(notification) {
    const payload = notification.payload;
    return {
        id: notification.id,
        text: message(notification),
        isShowPipeline: true,
        date_notify: notification.created_at,
        link: null,
        pipeline: {
            id: payload.pipeline_id,
            name: payload.pipeline_name,
            status: {
                status_id: payload.status_id,
                name: payload.status_name,
            },
            deal: {
                id: payload.deal_id,
                name: payload.deal_name,
                user: payload.user,
                date: payload.deal_date ?? null,
            },
        },
    };
}
