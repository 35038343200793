import { createApp } from 'vue';

import Label from '@/components/labels/Label.vue';

function message(notification) {
    const type = notification.payload.action_type;
    const map = {
        added: 'установил',
        deleted: 'удалил',
    };
    const label = document.createElement('span');
    const render = createApp(Label, {
        data: {
            name: notification.payload.label_name,
            color: notification.payload.color,
        },
    }).mount(label);

    return `
            <span class='font-bold'> ${map[type]} метку</span>
             ${label.innerHTML}
        </span>`;
}

export default function(notification) {
    const payload = notification.payload;
    return {
        id: notification.id,
        text: message(notification),
        isShowPipeline: true,
        date_notify: notification.created_at,
        link: null,
        pipeline: {
            id: payload.pipeline_id,
            name: payload.pipeline_name,
            status: {
                status_id: payload.status_id,
                name: payload.status_name,
            },
            deal: {
                id: payload.deal_id,
                name: payload.deal_name,
                user: payload.user,
                date: {
                    start_date: notification.payload.start_date,
                    end_date: notification.payload.end_date,
                },
            },
        },
    };
}
