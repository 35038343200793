import { Centrifuge, Subscription } from 'centrifuge';
import Event from '@/libs/centrifuge/Event';

/// event:client-#chanel client publish
/// event:name server publish
// struct { event:name,data:json,chanel:name}

export default class {
    /** @type {Subscription} */
    subscription = null;
    #handlers = {};

    constructor(subscription) {
        this.subscription = subscription;
        this.subscription.on('publication', (event) => {
            if (event.is_client) {
                const cb = this.#handlers[event.event];
                cb(event);
                return;
            }
            const cb = this.#handlers[event.data.event];
            if (cb) {
                cb(event.data);
            }
        });
        this.subscription.subscribe();
    }

    listen(event, cb) {
        this.#handlers[new Event(event).toString()] = cb;

        return this;
    }

    unsubscribe() {
        this.subscription.unsubscribe();
        this.#handlers = {};
    }

    listenForWhisper(event, cb) {
        this.#handlers[event] = cb;

        return this;
    }

    whisper(event, data) {
        data.event = event;
        data.is_client = true;
        this.subscription.publish(data);
    }
}
