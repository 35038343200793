export default class {
    #name = '';

    /**
     *
     * @param {string} name
     */
    constructor(name) {
        if (name.charAt(0) !== '.') {
            name = 'App\\Events\\' + name;
        }
        this.#name = name.replace('.', '');
    }

    toString() {
        return this.#name;
    }
}
