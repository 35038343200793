import dayjs from "dayjs";

function actionText(notifications) {
    const actions = {
        'created_check_list': 'создал',
        'deleted_check_list': 'удалил',
        'changed_check_list': 'обновил',
        'complete_check_list': 'завершил',
        'changed_execution_date_check_list': notifications.payload.check_list_execution_date ? 'установил дату' : 'удалил дату',
    }

    return `
        <span class="font-bold">${actions[notifications.type]}</span>
        <span class="font-bold" style=display:${notifications.type === 'changed_execution_date_check_list' && notifications.payload.check_list_execution_date != null ? 'inline' : 'none'}>
            ${dayjs(notifications.payload.check_list_execution_date).format('DD.MM.YYYY')}
        </span>
        чек-лист
        <span class="font-bold">${notifications.payload.check_list_name}</span>
       </spam>`;
}

export default function (notifications) {
    const payload = notifications.payload;
    return {
        id: notifications.id,
        text: actionText(notifications),
        isShowPipeline: true,
        date_notify: notifications.created_at,
        link: null,
        pipeline: {
            id: payload.pipeline_id,
            name: payload.pipeline_name,
            deal: {
                id: payload.deal_id,
                name: payload.deal_name,
                user: payload.user,
                date: payload.deal_date ?? null,
            },
        },
    };
}
