function message(notification) {
  const type = notification.payload.action_type;
      return `
            ответил на вашу заметку в сделке
        </span>`;
}

export default function(notification) {
  const payload = notification.payload;
  return {
    id: notification.id,
    text: message(notification),
    isShowPipeline: true,
    date_notify: notification.created_at,
    note_id: notification.payload.note_id,
    link: null,
    pipeline: {
      id: payload.pipeline_id,
      name: payload.pipeline_name,
      status: {
        status_id: payload.status_id,
        name: payload.status_name,
      },
      deal: {
        id: payload.deal_id,
        name: payload.deal_name,
        user: payload.user,
        date: {
          start_date: notification.payload.start_date,
          end_date: notification.payload.end_date,
        },
      },
    },
  };
}
