<template>
    <div class="bg-white pt-4 p-3 rounded-md shadow-md cursor-auto">
        <div class="flex mb-4 justify-between items-center border-b pb-3">
            <h3 class="font-bold text-lg mb-0 text-gray-500">Уведомления</h3>
            <div class="flex items-center gap-2">
                <span class="cursor-pointer" :title="getTitleSound" @click="handleSound">
                    <Volume2Icon v-if="getIsSoundNotify" />
                    <VolumeXIcon v-if="!getIsSoundNotify" />
                </span>
                <label for="only-not-read" class="font-medium text-sm text-right text-gray-500">
                    Показывать только<br />
                    непрочитанные
                </label>
                <input
                    :checked="isShowOnlyNotReadNotifications"
                    id="only-not-read"
                    class="form-check-switch min-w-[38px]"
                    type="checkbox"
                    @change="handleChangeOnlyNotRead(isShowOnlyNotReadNotifications)"
                />
            </div>
        </div>
        <slot name="content"></slot>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import store from '@/store';

export default {
    name: 'NotificationsModal',
    props: {
        isShowOnlyNotReadNotifications: {
            required: true,
        },
    },
    created() {
        window.Echo.private('App.Models.User.' + store.getters['user/getUser'].id)
            .listenForWhisper('ChangedSoundEvent', (event) => {
                this.setIsSoundNotification(Boolean(event.value));
            });
    },
    computed: {
        ...mapGetters({
            getIsSoundNotify: 'user/getIsSoundNotify',
        }),
        getTitleSound() {
            return this.getIsSoundNotify ? 'Выключить звук уведомления' : 'Включить звук уведомления';
        },
    },
    methods: {
        ...mapMutations('user', ['setIsShowOnlyNotReadNotifications', 'setIsSoundNotification']),
        handleChangeOnlyNotRead(e) {
            this.$emit('on-change-only-not-read', Number(!this.isShowOnlyNotReadNotifications));
        },
        handleSound() {
            this.setIsSoundNotification(!this.getIsSoundNotify);
            const chanel = window.Echo.private('App.Models.User.' + store.getters['user/getUser'].id);
            chanel.whisper('ChangedSoundEvent', { value: this.getIsSoundNotify });
        },
    },
};
</script>

<style lang="scss" scoped></style>
