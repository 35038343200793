import dayjs from 'dayjs';

function actionText(notifications) {
    return `
        <span>
            Добавил вас исполнителем в
            <span class="font-bold">итем чек-листа</span>
            ${notifications.payload.list_item_name}
            <span class="font-bold">в чек-листе</span>
            ${notifications.payload.list_name}

        </span>

       </spam>`;
}

export default function(notifications) {
    const payload = notifications.payload;
    return {
        id: notifications.id,
        text: actionText(notifications),
        isShowPipeline: true,
        date_notify: notifications.created_at,
        link: null,
        pipeline: {
            id: payload.pipeline_id,
            name: payload.pipeline_name,
            deal: {
                id: payload.deal_id,
                name: payload.deal_name,
                user: payload.user,
                date: payload.deal_date ?? null,
            },
        },
    };
}
