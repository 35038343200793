import { Centrifuge } from 'centrifuge';
import Subscription from '@/libs/centrifuge/Subscription';

const Event = {};

export default class {
    #options = '';
    /** @type {Centrifuge} */
    #centrifuge = null;
    #subscriptions = {};

    constructor(options, config) {
        this.#options = options;
        this.#centrifuge = new Centrifuge(this.#options, config);
        this.#centrifuge.connect();
    }

    private(channel) {
        return this.channel(channel);
    }

    /**
     *
     * @param channel
     * @returns Subscription
     */
    channel(channel) {
        let subscription = this.#subscriptions[channel];

        if (!subscription) {
            subscription = this.#centrifuge.getSubscription(channel);
            subscription = new Subscription(subscription ? subscription : this.#centrifuge.newSubscription(channel));
            this.#subscriptions[channel] = subscription;
        }

        return subscription;
    }

    leave(channel) {
        const subscription = this.#subscriptions[channel];

        if (subscription) {
            subscription.unsubscribe();
            delete this.#subscriptions[channel];
        }
    }
}
