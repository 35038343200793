import { helper } from '@/utils/helper';
import axios from 'axios';
import { TYPE_CERT, TYPE_NEWCERT, TYPE_ROS } from '@/constants/qr-codes';
import { RUSTEST_ROLE } from '@/constants/roles';

const state = () => {
    return {
        dealsMenu: {},
        menu: [
            {
                icon: 'LayersIcon',
                pageName: 'leads',
                title: 'Заявки',
                lockedFor: new Set([RUSTEST_ROLE]),
            },
            {
                icon: 'LayersIcon',
                pageName: 'deals',
                title: 'Сделки',
                is_drag: true,
                subMenu: [],
                lockedFor: new Set([RUSTEST_ROLE]),
            },
            {
                icon: 'LayoutIcon',
                pageName: 'clients',
                params: '',
                title: 'Клиенты',
                lockedFor: new Set([RUSTEST_ROLE]),
                subMenu: [
                    {
                        icon: 'TruckIcon',
                        pageName: 'company',
                        params: '',
                        title: 'Компании',
                        lockedFor: new Set([RUSTEST_ROLE]),
                    },
                    {
                        icon: 'PhoneIcon',
                        pageName: 'contacts',
                        params: '',
                        title: 'Контакты',
                        lockedFor: new Set([RUSTEST_ROLE]),
                    },
                ],
            },
            {
                icon: 'LayoutIcon',
                pageName: 'document',
                params: '',
                title: 'Документы',
                lockedFor: new Set([RUSTEST_ROLE]),
            },
            {
                icon: 'DatabaseIcon',
                pageName: '1С',
                params: '',
                title: '1С',
            },
            {
                icon: 'LayoutIcon',
                pageName: 'roszdravnadzor',
                params: '',
                title: 'Росздравнадзор',
                childName: ['roszdravnadzorEdit', 'roszdravnadzorCreate', 'roszdravnadzor'],
                lockedFor: new Set([RUSTEST_ROLE]),
            },
            {
                icon: 'QrCodeIcon',
                pageName: 'qr-codes',
                params: '',
                title: 'QR коды',
                childName: ['qr-codes-' + TYPE_CERT + '-category'],
                subMenu: [
                    {
                        icon: 'QrCodeIcon',
                        pageName: 'qr-codes-' + TYPE_CERT,
                        path: '/qrcodes/' + TYPE_CERT,
                        title: 'Сертификаты НСТ',
                        lockedFor: new Set([RUSTEST_ROLE]),
                    },
                    {
                        icon: 'QrCodeIcon',
                        pageName: 'qr-codes-' + TYPE_ROS,
                        path: '/qrcodes/' + TYPE_ROS,
                        title: 'Протоколы Испытаний',
                        onlyFor: new Set([RUSTEST_ROLE]),
                    },
                    {
                        icon: 'QrCodeIcon',
                        pageName: 'qr-codes-' + TYPE_NEWCERT,
                        path: '/qrcodes/' + TYPE_NEWCERT,
                        title: 'Сертификаты Рус-Тест',
                        onlyFor: new Set([RUSTEST_ROLE]),
                    },
                ],
            },
            {
                icon: 'DatabaseIcon',
                pageName: 'reestrs',
                childName: ['medreestr'],
                params: '',
                title: 'Реестры',
                lockedFor: new Set([RUSTEST_ROLE]),
            },
            {
                icon: 'InboxIcon',
                pageName: 'inbox',
                childName: ['createInbox', 'inbox-show', 'message-inbox'],
                params: '',
                title: 'Почта',
                lockedFor: new Set([RUSTEST_ROLE]),
            },
            {
                icon: 'UsersIcon',
                pageName: 'users',
                params: '',
                title: 'Пользователи',
                lockedFor: new Set([RUSTEST_ROLE]),
            },
            {
                icon: 'FolderIcon',
                pageName: 'permissions',
                params: '',
                title: 'Права доступа',
                lockedFor: new Set([RUSTEST_ROLE]),
            },
        ],
        formattedMenu: null,
    };
};

// getters
const getters = {
    menu: (state) => state.menu,
    formattedMenu: (state) => state.formattedMenu, // TODO вот это не правильно, нужно просто каждый раз на лету переопределять меню и всегда браз из state.menu
};

// actions
const actions = {
    async appendOrderSectionsMenu({ commit, getters, state }, { userRules, router }) {
        state.dealsMenu = getters.menu.find((m) => m.pageName === 'deals');
        if (!state.dealsMenu) return;
        try {
            const funnels = await axios.get('/menu-pipeline');
            if (state.dealsMenu?.subMenu.length > 0) {
                state.dealsMenu.subMenu.splice(0, state.dealsMenu.subMenu.length);
            }
            for (const funnel of funnels.data.data) {
                state.dealsMenu.subMenu.push({
                    icon: 'DiscIcon',
                    pageName: 'funnel',
                    count: funnel.deals_count,
                    path: `/pipeline/${funnel.id}/show`,
                    lockedFor: new Set([RUSTEST_ROLE]),
                    title: funnel.name,
                    params: { id: funnel.id },
                });
            }
            state.dealsMenu.subMenu.push({
                icon: 'SettingsIcon',
                pageName: 'funnelSettings',
                title: 'Настройки воронок',
                lockedFor: new Set([RUSTEST_ROLE]),
                params: '',
            });

            commit('setFormattedMenu', helper.allowedMenu(getters.menu, userRules, router));
        } catch (e) {}
    },
};

// mutations
const mutations = {
    setMenu(state, payload) {
        state.menu = payload;
    },
    clearMenuState(state) {
        state.menu = null;
    },
    setFormattedMenu(state, payload) {
        state.formattedMenu = payload;
    },
    addDealsMenu(state, payload) {
        let length = state.menu[1].subMenu.length;
        state.menu[1].subMenu.splice(length - 1, 0, {
            icon: 'DiscIcon',
            pageName: 'funnel',
            path: `/pipeline/${payload.id}/show`,
            title: payload.name,
            lockedFor: [RUSTEST_ROLE],
            params: { id: payload.id },
        });
    },
    addAddedEventMenu(state, payload) {
        let index = 0;
        if (state.dealsMenu.subMenu.length === 1) {
            index = payload.index - 1;
        } else {
            index = payload.index - 2;
        }
        state.dealsMenu.subMenu.splice(index, 0, {
            icon: 'DiscIcon',
            pageName: 'funnel',
            count: payload.pipeline.deals_count ?? 0,
            path: `/pipeline/${payload.pipeline.id}/show`,
            lockedFor: new Set([RUSTEST_ROLE]),
            title: payload.pipeline.name,
            params: { id: payload.pipeline.id },
        });
    },
    detachedMenu(state, payload) {
        const findIndex = state.dealsMenu.subMenu.findIndex((el) => el.params.id === payload.pipeline.id);
        if (findIndex !== -1) {
            state.dealsMenu.subMenu.splice(findIndex, 1);
        }
    },
    countAdd(state, id) {
        const pipeline = state.menu[1].subMenu.find((el) => el.params.id == id);
        pipeline.count += 1;
    },
    countRemove(state, id) {
        const pipeline = state.menu[1].subMenu.find((el) => el.params.id == id);
        pipeline.count -= 1;
    },
    rename(state, payload) {
        const pipeline = state.menu[1].subMenu.find((el) => el.params.id == payload.id);

        if (pipeline) {
            pipeline.title = payload.name;
        }
    },
    addCount(state, payload) {
        const pipeline = state.menu[1].subMenu.find((el) => el.params.id === payload.pipeline_id);
        pipeline.count += payload.count;
    },
    removeCount(state, payload) {
        const pipeline = state.menu[1].subMenu.find((el) => el.params.id === payload.pipeline_id);
        pipeline.count -= payload.count;
    },
    delDealMenu(state, id) {
        const index = state.menu[1].subMenu.findIndex((el) => el.params.id == id);
        state.menu[1].subMenu.splice(index, 1);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
