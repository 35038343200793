function moveDealText(notifications) {
    return `
        <span>
        переместил  сделку
        со статуса <span class='font-bold'>${notifications.payload.old_status_name ?? 'неизвестно'}</span>
        в статус  <span class='font-bold'>${notifications.payload.status_name}</span>
       </span>`;
}

export default function(notifications) {
    const payload = notifications.payload;
    return {
        id: notifications.id,
        text: moveDealText(notifications),
        isShowPipeline: true,
        date_notify: notifications.created_at,
        link: null,
        pipeline: {
            id: payload.pipeline_id,
            name: payload.pipeline_name,
            deal: {
                id: payload.deal_id,
                name: payload.deal_name,
                user: payload.user,
                date: payload.deal_date ?? null,
            },
        },
    };
}
