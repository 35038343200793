<template>
    <span class="badge" :class="status">
        <component v-if="icon" :is="icon" class="w-4 h-4 mr-2 text-black" />
        <span v-if="message">{{ message }}</span>
        <slot v-else />
    </span>
</template>
<script>
export default {
    name: 'StatusBadge',
    props: {
        status: String,
        message: String,
        icon: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
.badge {
    @apply px-3 py-1 rounded-full;
    display: inline-flex;
    align-items: center;

    &.default {
        @apply bg-white text-black;
    }

    &.success {
        @apply bg-green-100 text-green-700;
    }

    &.fail {
        @apply bg-red-100 text-red-700;
    }

    &--sm {
        @apply text-xs px-2 py-0.5;
    }
}
</style>
